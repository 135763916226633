/* Define a custom scrollbar for webkit browsers (Chrome, Safari) */
*::-webkit-scrollbar {
            width: 7px;
            /* Set the width of the sidebar */
}

/* Define the scrollbar track */
*::-webkit-scrollbar-track {
    background-color: transparent;
    /* Set the background color to transparent */
}

/* Define the scrollbar thumb */
*::-webkit-scrollbar-thumb {
    background-color: #424242;
    /* Set the thumb color to #424242 */
     border-radius: 6px;
}

/* Define the scrollbar thumb on hover */
    *::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Change thumb color on hover (optional) */
}

/* Define a custom scrollbar for Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #424242 transparent;
     /* Set thumb and track colors */
}

/* Define the scrollbar thumb in Firefox */
/* Note: Firefox does not support hover styles for scrollbar thumb */
* scrollbar-thumb {
    background-color: #424242;
    /* Set the thumb color to #424242 */
    border-radius: 6px;
}