@font-face {
  font-family: 'Roboto400';
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto500';
  src: url('./assets/fonts/Roboto/Roboto-Medium.ttf');
}

#root {
  height: 100vh;
  width: 100vw;
}